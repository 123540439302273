.line-item {
    padding: 25px 30px 25px 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d2d2d2;
    position: relative;
}
.line-item .disabled {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.6);
}
.line-item .column-left {
    width: 60%;
}
.line-item .title {
    margin: 0px;
    font-family: RalewayBold;
}
.line-item .title svg {
    margin-left: 15px;
    color: #979797;
}
.line-item .title svg:hover {
    cursor: help;
}
.line-item .title span.location {
    font-family: RalewayBold;
}
.line-item ul.badges {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    margin-top: 10px;
}
.line-item ul.badges li {
    font-size: 12px;
    display: inline;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    background-color: #f1f1f1;
    margin-right: 5px;
}
.line-item .item-type {
    text-align: left;
    display: none;
}
.line-item .type-label {
    font-size: 14px;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    background-color: #f1f1f1;
}
.line-item .item-options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.line-item span.hours {
    font-size: 22px;
    font-family: RalewayBold;
}
.line-item .item-options svg {
    font-size: 22px;
}
.line-item .item-options svg:hover {
    cursor: pointer;
}
.MuiPopover-paper {
    padding: 10px;
}
.option {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 8px;
}
.option svg {
    margin-right: 10px;
}
.option.delete svg {
    color: red;
}
.option.modify svg {
    color: orange;
}
.option:hover {
    cursor: pointer;
}

/* iPad Portrait */
@media only screen and (min-width: 768px) {
    .line-item .column-right {
        width: 40%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .line-item .item-type {
        display: block;
    }
}