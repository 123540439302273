.bottom-nav {
    width: 90%;
    height: 70px;
    border-radius: 100px;
    position: fixed;
    z-index: 99;
    bottom: 25px;
    left: 5%;
    right: 0;
    background-color: white;
    box-shadow: 0 5px 10px -5px rgb(0 0 0 / 10%), 0 5px 5px -5px rgb(0 0 0 / 4%);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.nav-item {
    text-align: center;
    width: 33%;
}
.nav-item:hover {
    cursor: pointer;
}
.nav-item p {
    font-family: RalewayBold;
    margin: 0px;
    font-size: 13px;
}
.nav-item:hover p {
    color: var(--lightblue);
}
.nav-item svg {
    font-size: 20px;
}
.nav-item:hover svg {
    color: var(--lightblue);
}

/* iPad portrait */
@media only screen and (min-width: 768px) {
    .bottom-nav {
        display: none;
    }
}