.MuiPaper-elevation.MuiDialog-paperScrollPaper {
    width: 100%;
    max-width: 450px;
    padding-top: 35px;
    margin: 5px;
}
.MuiPaper-root .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
}
.MuiPaper-root .close-icon:hover {
    cursor: pointer;
}
.MuiDialogContent-root {
    padding: 20px 10px;
}
.close-modal {
    text-align: center;
}
.close-modal:hover {
    text-decoration: underline;
    cursor: pointer;
}