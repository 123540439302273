:root {
	--lightblue: #13A3F6;
    --darkerblue: #008bdc;
}
@font-face {
    font-family: RalewayRegular;
    src: url('../assets/fonts/Raleway-Regular.ttf');
}
@font-face {
    font-family: RalewayBold;
    src: url('../assets/fonts/Raleway-Bold.ttf');
}
@font-face {
    font-family: RalewayLight;
    src: url('../assets/fonts/Raleway-Light.ttf');
}
html, body {
    max-width: 100%;
    overflow-x: hidden;
}
h1 {
    margin: 0px;
    font-family: RalewayBold;
    color: var(--lightblue);
}
h2 {
    margin: 0px;
    font-family: RalewayBold;
}
h3 {
    margin: 0px;
    font-family: RalewayBold;
}
input {
    font-family: RalewayRegular;
}
p {
    font-family: RalewayRegular;
}
a {
    font-family: RalewayRegular;
}
li {
    font-family: RalewayRegular;
}
span {
    font-family: RalewayRegular;
}
button:hover {
    cursor: pointer;
}
.main {
    background-color: #f5f5f5;
    min-height: 100vh;
    padding-bottom: 100px;
}
.container {
    max-width: 1310px;
    margin: 0 auto;
    padding: 0px 20px 0px 20px;
}
.content {
    padding-top: 50px;
    padding-bottom: 50px;
}
.submit-login span {
    font-family: RalewayBold;
}
.register {
    margin-top: 35px;
}
.MuiDialogContent-root .register-form {
    width: auto !important;
}
.heading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.heading h1 {
    max-width: 80%;
}
span.time {
    position: relative;
    font-size: 20px;
}
span.time svg {
    position: absolute;
    left: -30px;
    top: 4px;
}
.days {
    background-color: white;
    border: 1px solid #cfd6e4;
    border-radius: 4px;
    position: relative;
    z-index: 10;
}
.days-heading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 20px 30px;
    background-color: var(--lightblue);
    color: white;
}
.MuiSelect-select {
    font-family: RalewayRegular !important;
    padding: 10px !important;
}
p.no-options {
    text-align: center;
    margin: 0px;
}
.registration-submit {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.registration-submit input {
    width: 40%;
    font-size: 18px;
    padding: 10px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
}
.registration-submit button {
    width: 50%;
    padding: 10px;
    background-color: var(--lightblue);
    color: white;
    font-family: RalewayBold;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
}
.registration-submit button:hover {
    cursor: pointer;
    background-color: var(--darkerblue);
}
.skeletons-hours {
    padding: 20px 30px;
}
.skeletons-hours .loader {
    margin: 10px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.no-registrations {
    font-size: 18px;
    text-align: center;
    margin: 30px 0px;
    color: #8c8c8c;
}
.day-actions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 10px 30px;
}
.day-actions .action-button {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid var(--lightblue);
    color: var(--lightblue);
    background-color: white;
    font-family: RalewayBold;
    border-radius: 4px;
}
.day-actions .action-button span {
    font-family: RalewayBold;
}
.day-actions .totals {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.day-actions .totals p {
    font-family: RalewayBold;
    font-size: 22px;
    color: var(--lightblue);
}
.day-actions .totals svg {
    font-size: 22px;
    color: white;
}
.Toastify__toast {
    font-family: RalewayBold;
}
#artwork-gemini {
    display: none;
}

/* iPad Portrait */
@media only screen and (min-width: 768px) {
    .main {
        padding-top: 100px;
        padding-bottom: 0px;
        min-height: calc(100vh - 120px);
    }
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) {
    .register {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    .column-register {
        width: 27%;
        margin-bottom: 0px;
    }
    .column-register .register-form {
        width: 100% !important;
    }
    .days {
        width: 65%;
    }
    #artwork-gemini {
        display: block;
    }
}
