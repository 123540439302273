.dropdown-ghost {
    background-color: #ebebeb;
    position: relative;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    padding: 10px;
}
.dropdown-ghost:hover {
    cursor: not-allowed;
}
.dropdown-ghost.invalid {
    border: 1px solid red;
}
.dropdown-ghost p {
    margin: 0px;
    color: #a3a3a3;
}
.dropdown-ghost svg {
    color: #7b7b7b;
    position: absolute;
    top: 15px;
    right: 12px;
    font-size: 11px;
}