.MuiSvgIcon-root {
    fill: var(--lightblue) !important;
}
.MuiPickersCalendarHeader-label {
    font-family: RalewayRegular !important;
}
.MuiDayPicker-header {
    display: none !important;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
    font-family: RalewayRegular !important;
    font-size: 14px !important;
}
.MuiPickersDay-today {
    font-family: RalewayRegular !important;
    font-size: 14px !important;
}
.MuiButtonBase-root.Mui-selected {
    background-color: var(--lightblue) !important;
    font-family: RalewayRegular !important;
    font-size: 14px !important;
}