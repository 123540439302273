:root {
	--lightblue: #13A3F6;
}
.login {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    min-height: 100vh;
}
.login-form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: white;
}
.login-form img {
    max-width: 225px;
    margin-bottom: 35px;
}
.form {
    position: relative;
    width: 100%;
    max-width: 350px;
}
.form .form-field {
    width: calc(100% - 22px);
    padding: 10px;
    border: 1px solid var(--lightblue);
    border-radius: 5px;
    margin: 0px 0px 20px 0px;
    font-size: 15px;
    font-family: RalewayRegular;
}
.form .submit-login {
    width: 100%;
    padding: 10px;
    background-color: var(--lightblue);
    color: white;
    text-align: center;
    border: 0px;
    border-radius: 5px;
    font-size: 16px;
}
.form .submit-login:hover {
    cursor: pointer;
}
.form .form-link {
    color: var(--lightblue);
    text-align: center;
    margin-top: 15px;
}
.form .form-link:hover {
    cursor: pointer;
    text-decoration: underline;
}
.form p.success {
    text-align: center;
}
.form p.error {
    width: 100%;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 0%);
    color: red;
    text-align: center;
}
.image {
    display: none;
}

/* iPad portrait */
@media only screen and (min-width: 768px) {
    .login-form {
        width: 50%;
    }
    .image {
        display: block;
        min-height: 100vh;
        width: 50%;
        background-image: url('../../assets/images/boats.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
}
